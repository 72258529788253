<template>
<div v-if="getAllowFullSite" class="main content-page upcoming-events">

    <div class="content-sidebar no-sidebar-background">
        <img src="~@/assets/jigsaw-puzzle.png" style="max-width: 10em;">
        <div style="margin: 1em 0;">
            <p>The Great Awakening Project is a collaborative network of Christian movements, ministries, organizations, leaders, and individuals who are determined to accelerate the cause of Christ’s Kingdom advancement in every Sphere of society in America and around the world.</p>
        </div>
    </div>

    <div class="content-page-inner">
        <h1>Upcoming Events</h1>
        <div v-if="list.length > 0">
            <div v-for="(item, index) in list" class="event-wrapper">
                <div v-if="item.image_url" style="margin: 0 0 1em 0;">
                    <a v-if="item.link_url" :href="item.link_url">
                        <img :src="item.image_url" :alt="item.alt_text || item.description" />
                    </a>
                    <img v-else :src="item.image_url" :alt="item.alt_text || item.description" />
                </div>
                <h2>{{ item.description }}</h2>
                <div v-if="item.excerpt" v-html="item.excerpt"></div>
                <div v-else v-html="item.detailed_description"></div>
                <p v-if="!item.image_url && item.link_url">
                    <a v-if="item.link_url" :href="item.link_url">See details here...</a>
                </p>
            </div>
        </div>
        <div v-else class="message">
            There are no upcoming events.
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'upcoming-events',
    data () {
        return {
            loading: true,
            message: '',
            list: []
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        this.getList()
    },
    methods: {
        getList() {
            this.apiCall({ destination: 'get_list_events' }).then(obj => {
                this.loading = false
                if (obj.status == 'success') {
                    this.list = obj.events
                } else {
                    this.message = obj.message
                }
            })
        },
		registerFor(registrationCode) {
			this.$router.push('/register?event_code=' + registrationCode)
		}
    }
}
</script>

<style lang="scss" scoped>
.event-wrapper {
    border-bottom: 1px solid #ccc;
    padding: 1em 0;
}
.event-wrapper:last-of-type {
    border-bottom: 0 none;
}
</style>
